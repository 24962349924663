<template lang="pug">
	div(v-if="item && item.isNeuro()")
		project-alerts
		.features
			h2.features__title {{ $t('project.features') }}
			.card-basic.position-relative.overflow-hidden.features__wrap
				.d-flex.align-items-center.features__item
					.flex-shrink-0.features__icon 🤖
					span.b1 {{ $t('features.item1') }}
				.d-flex.align-items-center.features__item
					.flex-shrink-0.features__icon 💰
					span.b1 {{ $t('features.item2') }}
				a.btn.btn-primary.features__btn(href='https://docs.paywall.pw/rus/neirobot/vozmozhnosti' target='_blank') {{ $t('features.goToKnowledgeBase') }}
				.features__image
					.features__image-books(style="background: url('/assets/img/dashboard/books-1.webp') center / contain no-repeat")

</template>

<script>
import { mapGetters } from 'vuex';
import ProjectAlerts from '@/components/Cards/ProjectAlerts';

export default {
    name: 'Features',
    components: {
        ProjectAlerts,
    },
    computed: {
        ...mapGetters({
            item: 'project/opened',
        }),
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.features {
    &__title {
        margin-bottom: 19.5px !important;
    }

    &__wrap {
        @include respond-below(sm) {
            padding: 14px 17px;
        }

        @include respond-below(xm) {
            padding-bottom: 140px;
        }
    }

    &__item {
        margin-bottom: 20px;

        @include respond-below(lg) {
            max-width: 400px;
        }

        @include respond-below(md) {
            max-width: 100%;
        }

        @include respond-below(sm) {
            max-width: 329px;
        }

        @include respond-below(xm) {
            max-width: 100%;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__icon {
        font-size: 16px;
        display: flex;
        padding-top: 8px;
        justify-content: center;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        background-color: var(--primary-bg-color);
        margin-right: 15px;
    }

    &__btn {
        background: linear-gradient(260.48deg, #0071f4 5.07%, #66b4ff 98.17%);
        max-width: 329px;
        color: #fff !important;
        margin-top: 30px;

        @include respond-below(sm) {
            height: 50px;
            margin-top: 31px;
        }

        @include respond-below(xm) {
            max-width: 100%;
        }

        &:hover,
        &:active {
            color: #fff !important;
            text-decoration: none;
        }

        &:active:focus {
            box-shadow: inset 0px 1px 2px #1a60a3 !important;
        }

        &:focus {
            box-shadow: none;
        }
    }

    &__image {
        border-radius: 50%;
        width: 329px;
        height: 329px;
        background-color: var(--primary-bg-color);
        position: absolute;
        bottom: -28%;
        right: -77px;

        @include respond-below(lg) {
            right: -170px;
        }

        @include respond-below(xm) {
            width: 292px;
            height: 292px;
            right: 0;
            left: 0;
            margin: 0 auto;
            bottom: -165.5px;
        }

        &-books {
            position: absolute;
            top: 32%;
            right: 58.15%;
            width: 203px;
            height: 116px;

            @include respond-below(xm) {
                width: 139px;
                height: 79px;
                right: 0;
                left: 0;
                top: 29px;
                margin: 0 auto;
            }
        }
    }
}
</style>
